import axios from "axios";

const initiateApiCall = (
    method: string,
    endpoint: string,
    payload: any | null,
    headers: any,
    params?: any,
    responseType?: any
) => {
    return axios({
        method: method,
        url: endpoint,
        data: payload,
        headers: headers,
        params: params,
        responseType: responseType
    });
};

export { initiateApiCall };
