import {
  GET_APP_DETAILS_API_INITIATED,
  GET_APP_DETAILS_API_SUCCEED,
  GET_APP_DETAILS_API_FAILED,
} from "../constants/appDetailsConstants";

export const getAppDetailsApiInitiated = () => ({
  type: GET_APP_DETAILS_API_INITIATED,
});

export const getAppDetailsApiSucceed = (payload: any) => ({
  type: GET_APP_DETAILS_API_SUCCEED,
  payload: payload,
});

export const getAppDetailsApiFailed = (payload: any) => ({
  type: GET_APP_DETAILS_API_FAILED,
  payload: payload,
});
