import React, { useState } from "react";
import { formatDate, formatNumberToK } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import "./myAppsCardContent.scss";
import {
  playstoreLogoIcon,
  appleIcon,
  filledStar,
  editIcon,
  moreButtonDots,
} from "../../../assets/index";
import { Link } from "react-router-dom";
import FallbackImage from "../../../components/appImage/FallBackImage";

const IMG_SRC: any = {
  android: playstoreLogoIcon,
  ios: appleIcon,
};

const MyAppsCardContent = ({
  appName,
  appDescription,
  appLogo,
  appAccountHolderName,
  appLastUpdateDate,
  averageRating,
  noOfReviews,
  noOfDownloads,
  platformRegistered,
  packageName,
  platformName,
  appStoreLogo,
  appStatus,
  appApprovalStatus,
}: any) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleEditClick = () => {
    if (appApprovalStatus == "full_registered") {
      editApp();
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleMoreEditClick = () => {
    setIsOpen(!isOpen);
    editApp();
  };

  const handleMoreDeleteClick = () => {
    setIsOpen(!isOpen);
  };

  const editApp = () => {
    navigate("/registerApp", {
      state: {
        packageName: packageName,
        platformName: platformName,
        from: "myApps",
        action: "update",
      },
    });
  };

  return (
    <div className="card-content">
      <div className="app-logo-details">
        <div className="app-logo-container">
          <div className="app-logo">
            <FallbackImage
              src={appStoreLogo}
              fallbackSrc={appLogo}
              alt={appName}
              height={"100"}
              width={"100"}
            />
          </div>
        </div>
        {noOfDownloads > 0 && (
          <div className="app-no-of-downloads-container">
            <div className="app-no-of-downloads">
              {formatNumberToK(noOfDownloads)}
            </div>
            <div className="app-no-of-downloads-subtext">downloads</div>
          </div>
        )}
      </div>
      <div className="app-content-container">
        <div className="app-name-container">
          <div className="app-name">{appName}</div>
          <div className="icons-container">
            <img
              className="my-apps-card-icon"
              src={IMG_SRC[platformName]}
              alt={platformName}
            />
            {appApprovalStatus !== "soft_registered" && (
              <img
                className="my-apps-card-icon"
                src={
                  appApprovalStatus == "full_registered"
                    ? editIcon
                    : moreButtonDots
                }
                alt="editIcon"
                onClick={handleEditClick}
              />
            )}
            {isOpen && (
              <div className="dropdown-menu">
                <div className="dropdown-item" onClick={handleMoreEditClick}>
                  <img
                    src={editIcon}
                    alt="editIcon"
                    className="dropdown-icon"
                  />{" "}
                  Edit
                </div>
                {appApprovalStatus !== "rejected" && (
                  <div
                    className="dropdown-item"
                    onClick={handleMoreDeleteClick}
                  >
                    <img
                      src={editIcon}
                      alt="deleteIcon"
                      className="dropdown-icon"
                    />{" "}
                    Delete
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className="app-content-container"
          onClick={() =>
            navigate("/appDetails", {
              state: { packageName: packageName, platformName: platformName },
            })
          }
        >
          <div className="app-account-holder-name">{appAccountHolderName}</div>
          <div className="app-rating-container">
            <span>
              {averageRating > 0 && (
                <span className="app-rating">
                  {averageRating} <img src={filledStar} alt="star" />
                </span>
              )}
              {noOfReviews > 0 && (
                <span className="app-no-of-reviews">
                  {`(${formatNumberToK(noOfReviews)} reviews)`}
                </span>
              )}
            </span>
          </div>
          {appLastUpdateDate && (
            <div className="app-publish-date">
              {`Last published date : ${formatDate(
                new Date(appLastUpdateDate)
              )}`}
            </div>
          )}
          <div className="app-info">{appDescription}</div>
        </div>
      </div>
    </div>
  );
};

export default MyAppsCardContent;
