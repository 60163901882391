import {
    POST_REGISTER_API_INITIATED,
    POST_REGISTER_API_SUCCEED,
    POST_REGISTER_API_FAILED,
    GET_LINE_OF_BUSINESS_API_INITIATED,
    GET_LINE_OF_BUSINESS_API_SUCCEED,
    GET_LINE_OF_BUSINESS_API_FAILED,
} from "../constants/registerAppConstants";

export const postRegisterApiInitiated = () => ({
    type: POST_REGISTER_API_INITIATED,
});

export const postRegisterApiSucceed = (payload: any) => ({
    type: POST_REGISTER_API_SUCCEED,
    payload: payload,
});

export const postRegisterApiFailed = (payload: any) => ({
    type: POST_REGISTER_API_FAILED,
    payload: payload,
});

export const getLineOfBusinessApiInitiated = () => ({
    type: GET_LINE_OF_BUSINESS_API_INITIATED,
});

export const getLineOfBusinessApiSucceed = (payload: any) => ({
    type: GET_LINE_OF_BUSINESS_API_SUCCEED,
    payload: payload,
});

export const getLineOfBusinessApiFailed = (payload: any) => ({
    type: GET_LINE_OF_BUSINESS_API_FAILED,
    payload: payload,
});
