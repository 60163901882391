import React from "react";
import { close_icon } from "../../../assets";
import "./ReviewPopup.scss";
import TabsComponent from "../../tabsComponent/TabsComponent";
import ReviewCardComponent from "../../reviewCardComponent/ReviewCardComponent";
import FallbackImage from "../../appImage/FallBackImage";

type ReviewPopupProps = {
  reviews: any;
  setEnablePopup: any;
  appName: string;
  appIconUrl: string;
  setReviewType: any;
  selectedTab: string;
  pageNumber: number;
  apiCallFlag: boolean;
  setReviewPageNumber: any;
  setReviewTypeChangedFlag: any;
  setApiCallFlag: any;
  reviewApiInitiated: boolean;
  fallbackSrcUrl: string;
};

const tabs = [
  { label: "All", value: "All" },
  { label: "Positive", value: "Positive" },
  { label: "Neutral", value: "Neutral" },
  { label: "Negative", value: "Negative" },
];

const ReviewPopup = ({
  reviews,
  setEnablePopup,
  appName,
  appIconUrl,
  setReviewType,
  selectedTab,
  pageNumber,
  apiCallFlag,
  setReviewPageNumber,
  setReviewTypeChangedFlag,
  setApiCallFlag,
  reviewApiInitiated,
  fallbackSrcUrl,
}: ReviewPopupProps) => {
  const handleScroll = (event: any) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target; // Get scroll position

    if (scrollHeight - scrollTop - clientHeight <= 1) {
      setTimeout(() => {
        if (apiCallFlag) {
          console.log("loading...");
          setReviewPageNumber(pageNumber + 1);
        }
      }, 2000);
    }
  };

  return (
    <div className="review-popup-overlay">
      <div className="review-popup">
        <div className="review-popup-header">
          <div className="review-popup-header-content">
            <div className="app-heading-deatils">
              <FallbackImage
                src={appIconUrl}
                fallbackSrc={fallbackSrcUrl}
                alt="App Image"
                height={"54"}
                width={"54"}
              />

              <div>
                <div className="app-review-heading">{appName}</div>
                <div className="app-review-sub-heading">
                  App ratings and reviews
                </div>
              </div>
            </div>

            <img
              src={close_icon}
              className="close-icon"
              onClick={() => setEnablePopup(false)}
            ></img>
          </div>
        </div>
        <div className="reviews-tab-container">
          <TabsComponent
            tabs={tabs}
            selectedTab={selectedTab}
            setTab={setReviewType}
            setReviewTypeChangedFlag={setReviewTypeChangedFlag}
            tabBackground={"primary"}
            setReviewPageNumber={setReviewPageNumber}
            setApiCallFlag={setApiCallFlag}
          />
        </div>
        <div className="reviews-container" onScroll={handleScroll}>
          <div className="review-card">
            <ReviewCardComponent
              reviews={reviews}
              reviewCardBackground={"primary"}
            />
            {reviewApiInitiated && (
              <div className="loading-text">loading...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReviewPopup;
