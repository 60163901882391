import React from "react";
import "./AppImageWithDescription.scss";
import { appImage, filledStar } from "../../../assets";
import {
  formatDate,
  formatNumberToK,
  formatNumberToSign,
} from "../../../utils/Utils";
import { appDetails } from "../AppDetailsScreen";
import FallbackImage from "../../../components/appImage/FallBackImage";

interface appDetailsProps {
  appDetails: appDetails;
}

const AppImageWithDescription = ({ appDetails }: appDetailsProps) => {
  return (
    <div className="app-image-with-description">
      <div className="app-image">
        <FallbackImage
          src={appDetails["app-details"]["store-app-logo"]}
          fallbackSrc={appDetails["app-details"]["app-logo"]}
          alt="App Image"
          height={"148"}
          width={"148"}
        />
      </div>
      <div className="app-description-wrapper">
        <div className="app-details-app-name">
          {appDetails["app-details"]["app-name"]}
        </div>
        <div className="app-status-package-name">
          {appDetails["app-details"]["app-status"]?.["status"] && (
            <div className="app-status">
              {appDetails["app-details"]["app-status"]["status"]}
            </div>
          )}
          {appDetails["app-details"]["app-status"]?.["status"] &&
            appDetails["app-details"]["package-name"] && (
              <div className="line"></div>
            )}
          <div className="app-details-package-name">
            {appDetails["app-details"]["package-name"]}
          </div>
        </div>
        <div className="account-name">
          {appDetails["app-details"]["app-account-holder"]}
        </div>
        <div className="app-reviews-downloads">
          <div>
            {appDetails["app-details"]["avg-rating"] !== 0 && (
              <div className="app-reviews-downloads-black-text">
                {appDetails["app-details"]["avg-rating"]}
                &nbsp;
                <img src={filledStar} alt="star" />
              </div>
            )}
            {appDetails["app-details"]["no-of-reviews"] !== 0 && (
              <div className="statistics">
                ({formatNumberToK(appDetails["app-details"]["no-of-reviews"])}{" "}
                reviews)
              </div>
            )}
          </div>
          {appDetails["app-details"]["no-of-reviews"] !== 0 &&
            appDetails["app-details"]["no-of-downloads"] !== 0 && (
              <div className="line-statistics"></div>
            )}
          {appDetails["app-details"]["no-of-downloads"] !== 0 && (
            <div>
              <div className="app-reviews-downloads-black-text">
                {formatNumberToSign(
                  appDetails["app-details"]["no-of-downloads"]
                )}
              </div>
              <div className="statistics">Downloads</div>
            </div>
          )}
          {appDetails["app-details"]["no-of-downloads"] !== 0 ||
            ((appDetails["app-details"]["avg-rating"] !== 0 ||
              appDetails["app-details"]["no-of-reviews"] !== 0) &&
              appDetails["app-details"]["app-last-published-date"] && (
                <div className="line-statistics"></div>
              ))}
          {appDetails["app-details"]["app-last-published-date"] && (
            <>
              <div>
                <div className="app-reviews-downloads-black-text">
                  {formatDate(
                    new Date(
                      appDetails["app-details"]["app-last-published-date"]
                    )
                  )}
                </div>
                <div className="statistics">Last published date</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppImageWithDescription;
