export const businessLines = [{
    "id": 1,
    "line-of-business": "AGB",
    "business-direction": "DB1"
  },
  {
    "id": 2,
    "line-of-business": "AOE",
    "business-direction": "DB1"
  },
  {
    "id": 3,
    "line-of-business": "ARB",
    "business-direction": "DB1"
  },
  {
    "id": 4,
    "line-of-business": "EXP",
    "business-direction": "DB1"
  },
  {
    "id": 5,
    "line-of-business": "MSP",
    "business-direction": "DB1"
  },
  {
    "id": 6,
    "line-of-business": "URB",
    "business-direction": "DB2"
  },
  {
    "id": 7,
    "line-of-business": "TLD",
    "business-direction": "DB2"
  },
  {
    "id": 8,
    "line-of-business": "DIS",
    "business-direction": "DB2"
  },
  {
    "id": 9,
    "line-of-business": "MIN",
    "business-direction": "DB3"
  },
  {
    "id": 10,
    "line-of-business": "OHT",
    "business-direction": "DB3"
  },
  {
    "id": 11,
    "line-of-business": "2W",
    "business-direction": "DB3"
  },
  {
    "id": 12,
    "line-of-business": "AV",
    "business-direction": "DB3"
  },
  {
    "id": 13,
    "line-of-business": "CSN",
    "business-direction": "DB3"
  },
  {
    "id": 14,
    "line-of-business": "CSS",
    "business-direction": "DB3"
  },
  {
    "id": 15,
    "line-of-business": "AST",
    "business-direction": "DB4"
  },
  {
    "id": 16,
    "line-of-business": "H2M",
    "business-direction": "DB4"
  },
  {
    "id": 17,
    "line-of-business": "MED",
    "business-direction": "DB4"
  },
  {
    "id": 18,
    "line-of-business": "PRP",
    "business-direction": "DB4"
  },
  {
    "id": 19,
    "line-of-business": "SES",
    "business-direction": "DB4"
  },
  {
    "id": 20,
    "line-of-business": "SNB MHT",
    "business-direction": "DB4"
  },
  {
    "id": 21,
    "line-of-business": "DCAQ",
    "business-direction": "CD"
  },
  {
    "id": 22,
    "line-of-business": "DCEM",
    "business-direction": "CD"
  },
  {
    "id": 23,
    "line-of-business": "DCF",
    "business-direction": "CD"
  },
  {
    "id": 24,
    "line-of-business": "DCIP",
    "business-direction": "CD"
  },
  {
    "id": 25,
    "line-of-business": "DCJ",
    "business-direction": "CD"
  },
  {
    "id": 26,
    "line-of-business": "DCP",
    "business-direction": "CD"
  },
  {
    "id": 27,
    "line-of-business": "DCPT",
    "business-direction": "CD"
  },
  {
    "id": 28,
    "line-of-business": "DCS",
    "business-direction": "CD"
  },
  {
    "id": 29,
    "line-of-business": "DCSE",
    "business-direction": "CD"
  },
  {
    "id": 30,
    "line-of-business": "DOA",
    "business-direction": "OD"
  },
  {
    "id": 31,
    "line-of-business": "DOCBS",
    "business-direction": "OD"
  },
  {
    "id": 32,
    "line-of-business": "DOEC",
    "business-direction": "OD"
  },
  {
    "id": 33,
    "line-of-business": "DOMF",
    "business-direction": "OD"
  },
  {
    "id": 34,
    "line-of-business": "DORD",
    "business-direction": "OD"
  },
  {
    "id": 35,
    "line-of-business": "DOSC",
    "business-direction": "OD"
  },
  {
    "id": 36,
    "line-of-business": "DOTI",
    "business-direction": "OD"
  }];
