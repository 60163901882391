import React from "react";
import "./ProgressBarWithLabel.scss";

type Props = {
  ratings: Array<{ label: string; value: number }>;
};

const ProgressBarWithLabel = ({ ratings }: Props) => {
  return (
    <div className="progress-bar-main-container">
      {ratings.map(({ label, value }) => (
        <div key={label} className="progress-bar-container">
          <label htmlFor="progress-bar">{label}</label>
          <progress id="progress-bar" value={value} max={100}>
            {value}%
          </progress>
        </div>
      ))}
    </div>
  );
};

export default ProgressBarWithLabel;
