import React, { useState } from 'react';
import { defaultIcon } from '../../assets';
import "./FallBackImage.scss";

type Props = {
    src: string,
    fallbackSrc: string,
    alt: string,
    height?: string,
    width?: string
}

const FallbackImage = ({ src, fallbackSrc, alt, height, width }: Props) => {
    const [imgSrc, setImgSrc] = useState(src);
    const [errorCount, setErrorCount] = useState(0);

    const handleError = () => {
        if (errorCount === 0) {
            if (imgSrc === fallbackSrc) {
                setImgSrc('')
            } else {
                setImgSrc(fallbackSrc);
            }
            setErrorCount(1);
        } else if (errorCount === 1) {
            setImgSrc("default");
            setErrorCount(2);
        }
    };
    return <img src={errorCount === 2 || imgSrc === null ? defaultIcon : imgSrc} alt={alt} onError={handleError} height={height} width={width} />;
};

export default FallbackImage;