import React from "react";
import check from "../../../assets/images/Group.png";
import "../AppAnalyticsScreen.scss";

const SubTable = ({
  businessDirName = "Default BD Name",
  tableMatrix = [],
  countOfAppsPerLB = [],
}: any) => {
  return (
    <div className="sub-table">
      <div className="sub-table-header-container">
        <h4 className="sub-table-header-container">{businessDirName}</h4>
      </div>
      <table>
        <tr>
          {Object.entries(countOfAppsPerLB)
            .sort((entry1, entry2) => {
              // Sort based on key (alphabetical order)
              return entry1[0].localeCompare(entry2[0]);
            })
            .map(([lbName, lbCount]: [any, any]) => (
              <th
                key={`${businessDirName}_${lbName}`}
                className="column-header"
              >
                <div className="buisness-line-text">
                  <div>{lbName}</div>
                  <div className="text-grey"> ({lbCount}) </div>
                </div>
              </th>
            ))}
        </tr>

        <tbody>
          {tableMatrix.map((appList: any) => (
            <tr key={tableMatrix.lbName}>
              {appList.map((isAppPresent: any, index: any) => (
                <td
                  key={`${tableMatrix.lbName}_${index}`}
                  className={`data-cell ${
                    isAppPresent ? "app-present" : "app-absent"
                  }`}
                >
                  {isAppPresent ? <img src={check} alt="check" /> : ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubTable;
