import {
    POST_REGISTER_API_INITIATED,
    POST_REGISTER_API_SUCCEED,
    POST_REGISTER_API_FAILED,
    GET_LINE_OF_BUSINESS_API_INITIATED,
    GET_LINE_OF_BUSINESS_API_SUCCEED,
    GET_LINE_OF_BUSINESS_API_FAILED,
} from "../constants/registerAppConstants";


const initialState = {
    apiCallInitiated: false,
    apiResponse: {},
    apiCallFailed: {},
};

const registerAppReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case POST_REGISTER_API_INITIATED:
            return {
                apiCallInitiated: true,
                apiResponse: {},
                apiCallFailed: {},
            };
        case POST_REGISTER_API_SUCCEED:
            return {
                apiCallInitiated: false,
                apiResponse: action.payload,
                apiCallFailed: {},
            };
        case POST_REGISTER_API_FAILED:
            return {
                apiCallInitiated: false,
                apiResponse: {},
                apiCallFailed: action.payload,
            };
        case GET_LINE_OF_BUSINESS_API_INITIATED:
            return {
                apiCallInitiated: true,
                apiResponse: {},
                apiCallFailed: {},
            };
        case GET_LINE_OF_BUSINESS_API_SUCCEED:
            return {
                apiCallInitiated: false,
                apiResponse: action.payload,
                apiCallFailed: {},
            };
        case GET_LINE_OF_BUSINESS_API_FAILED:
            return {
                apiCallInitiated: false,
                apiResponse: {},
                apiCallFailed: action.payload,
            };
        default:
            return state;
    }
};

export default registerAppReducers;