import React from "react";
import "../AppAnalyticsScreen.scss";

const SubTableAppList = ({ appList = [], onSearchQueryChange }: any) => {
  return (
    <div className="sub-table-app-list">
      <table>
        <th className="sub-table-app-list-header">
          <input
            type="text"
            placeholder="Search apps"
            className="sub-table-app-list-header-input"
            onChange={(e) => onSearchQueryChange(e.target.value)}
          />
        </th>
        <tbody>
          {appList.map((appName: any) => (
            <tr key={appName}>
              <td className="data-cell">{appName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubTableAppList;
