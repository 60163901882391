import React, { useState } from "react";
import "./AppSecretMetaData.scss";
import { eyeClosed, eyeOpen } from "../../../assets";
import { appDetails } from "../AppDetailsScreen";

interface appDetailsProps {
  appSecretMetaData: appDetails;
}

const AppSecretMetaData = ({ appSecretMetaData }: appDetailsProps) => {
  const [showValues, setShowValues] = useState(false);

  const checkValuesPresent =
    appSecretMetaData["app-details"]?.["app-secrets"]?.["APP-ID"] &&
    appSecretMetaData["app-details"]?.["app-secrets"]?.["APP-SECRET"];

  return checkValuesPresent ? (
    <div className="app-secret-meta-data-sub-container">
      <div className="app-secret-details">
        <div className="app-secrets-label">App Secrets</div>
        <div>
          <img
            src={!showValues ? eyeClosed : eyeOpen}
            alt="Eye Closed"
            className="eye-closed"
            onClick={() => setShowValues((prevState) => !prevState)}
          />
        </div>
      </div>
      <div className="app-id-secret">
        <div>
          <div className="app-meta-data-labels">App ID</div>
          <div className="app-meta-data-values">
            {!showValues
              ? "***************************"
              : appSecretMetaData["app-details"]["app-secrets"]["APP-ID"]}
          </div>
        </div>
        <div>
          <div className="app-meta-data-labels">App Secret</div>
          <div className="app-meta-data-values">
            {!showValues
              ? "***************************"
              : appSecretMetaData["app-details"]["app-secrets"]["APP-SECRET"]}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AppSecretMetaData;
