import React from "react";
import "./AppMetaData.scss";
import { appDetails } from "../AppDetailsScreen";

interface appDetailsProps {
  appMetaData: appDetails;
}

const AppMetaData = ({ appMetaData }: appDetailsProps) => {
  return (
    <div className="app-meta-data">
      <div>
        <div className="app-meta-data-labels">Category</div>
        <div className="app-meta-data-values">
          {appMetaData["app-details"]?.["store-app-category"]
            ? appMetaData["app-details"]?.["store-app-category"]?.trim()
            : appMetaData["app-details"]?.["app-category"]?.trim()
            ? appMetaData["app-details"]?.["app-category"]?.trim()
            : "NA"}
        </div>
        <div className="app-meta-data-labels app-meta-data-margin">
          App Version
        </div>
        <div className="app-meta-data-values">
          {appMetaData["app-details"]?.["app-version"]?.trim()
            ? appMetaData["app-details"]?.["app-version"]
            : "NA"}
        </div>
      </div>
      <div>
        <div className="app-meta-data-labels">App Type</div>
        <div className="app-meta-data-values">
          {appMetaData["app-details"]?.["app-type"]?.trim()
            ? appMetaData["app-details"]?.["app-type"]
            : "NA"}
        </div>
        <div className="app-meta-data-labels app-meta-data-margin">
          Build Version
        </div>
        <div className="app-meta-data-values">
          {appMetaData["app-details"]?.["store-app-build-version"]
            ? appMetaData["app-details"]?.["store-app-build-version"]?.trim()
            : appMetaData["app-details"]?.["build-version"]?.trim()
            ? appMetaData["app-details"]?.["build-version"]?.trim()
            : "NA"}
        </div>
      </div>
    </div>
  );
};

export default AppMetaData;
