import React from "react";
import { crossIcon } from "../../../assets";
import "./SuccessPopup.scss";

type SuccessPopupProps = {
  boldMessage: string;
  message: string;
  onClose: () => void;
  onRegisterNewApp?: () => void;
  onViewMyApps?: () => void;
  stateAction?: string;
};

const SuccessPopup = ({
  boldMessage,
  message,
  onClose,
  onRegisterNewApp,
  onViewMyApps,
  stateAction,
}: SuccessPopupProps) => {
  return (
    <div className="success-popup-overlay">
      <div className="success-popup">
        <div className="success-popup-header">
          <h2>Success!</h2>
          <button className="success-popup-close" onClick={onClose}>
            <img src={crossIcon} alt="Close" />
          </button>
        </div>
        <div className="success-popup-body">
          <div className="success-popup-text">
            <strong>{boldMessage}</strong>
            <p>{message}</p>
            <div className="success-popup-buttons">
              {stateAction === "register" && (
                <button
                  className="register-app-button"
                  onClick={onRegisterNewApp}
                >
                  Register new app
                </button>
              )}
              {stateAction === "register" && (
                <button className="view-apps-button" onClick={onViewMyApps}>
                  View my apps
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopup;
