import React from "react";
import "./ErrorPopup.scss";
import { crossIcon } from "../../../assets";

type ErrorPopupProps = {
  boldMessage: string;
  message: string;
  onClose: () => void;
};

const ErrorPopup = ({ boldMessage, message, onClose }: ErrorPopupProps) => {
  return (
    <div className="error-popup-overlay">
      <div className="error-popup">
        <div className="error-popup-header">
          <h2>ERROR</h2>
          <button className="error-popup-close" onClick={onClose}>
            <img src={crossIcon} alt="Close" />
          </button>
        </div>
        <div className="error-popup-body">
          <div className="error-popup-text">
            <strong>{boldMessage}</strong>
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErrorPopup;
