import React from "react";
import "../../components/capsuleComponent/CapsuleComponent.scss";
import { crossIcon } from "../../assets";

type Props = {
  appTypes: any;
  onSelect: any;
  selectedAppType: any;
};

const AppTypeCapsuleComponent = ({
  appTypes,
  onSelect,
  selectedAppType,
}: Props) => {
  return (
    <div
      className={
        "main-capsule-container" + (selectedAppType.length > 0 ? " chosen" : "")
      }
    >
      <div className="text-style">Select App Type</div>
      <div className={"capsule-container"}>
        {selectedAppType.length > 0 && (
          <div className="seleted-container">
            {selectedAppType.map((appType: any, index: any) => (
              <button key={index} className="capsule-button active">
                {appType}
                <img
                  src={crossIcon}
                  alt="close"
                  className="close-icon"
                  onClick={() => onSelect(appType, "remove")}
                />
              </button>
            ))}
            <div
              className="clear-button"
              onClick={() => onSelect([], "clearAll")}
            >
              Clear
            </div>
          </div>
        )}
        {appTypes.map((appType: any, index: any) => (
          <button
            key={index}
            className="capsule-button"
            onClick={() => onSelect(appType)}
          >
            {appType}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AppTypeCapsuleComponent;
