import React from "react";
import { blueFilledStar, emptyStar, halfFilledStar } from "../../assets";
import "./StarRatingsComponent.scss";

type StarRatingsComponentProps = {
  rating: number;
};

const StarRatingsComponent = ({ rating }: StarRatingsComponentProps) => {
  const filledStars = Math.round(rating * 2) / 2;

  return (
    <div className="stars-container">
      {Array.from({ length: filledStars }).map((_, index) => (
        <img key={index} src={blueFilledStar} alt="filled-star" />
      ))}
      {5 - filledStars === 0.5 && (
        <img src={halfFilledStar} alt="half-filled-star" />
      )}
      {Array.from({ length: 5 - filledStars }).map((_, index) => (
        <img key={index} src={emptyStar} alt="empty-star" />
      ))}
    </div>
  );
};

export default StarRatingsComponent;
