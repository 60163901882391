import React, { useState, useEffect } from "react";
import "./LoginScreen.scss";
import { useNavigate } from "react-router-dom";
import {
  loginImageXLarge,
  loginImagePoseXLarge,
  loginImageLarge,
  loginImagePoseLarge,
  michelinLogo,
} from "../../assets/index";
import { ProcessingDialog } from "../../components";
import { convertToBase64 } from "../../utils/Utils";
import { useAuth } from "../../routes/useAuth";

const platformDetailsList = [
  "View all available mobile apps at Michelin",
  "Register your app to take ownership & manage",
  "View app details as on stores",
  "View customer reviews about your app",
  "Manage obsolescence",
  "Analytics",
];
const LoginScreen = () => {
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const url = `${process.env.REACT_APP_FEDERATION_LOGIN_URL}`;
  const redirect_uri = `${process.env.REACT_APP_FEDERATION_CALL_BACK_URI}`;
  const client_id = `${process.env.REACT_APP_FEDERATION_CLIENT_ID}`;
  const navigate = useNavigate();
  const connectClick = () => {
    const uri = `${url}?response_type=code&client_id=${client_id}&scope=openid profile email&redirect_uri=${redirect_uri}`;
    window.location.href = uri;
  };

  const { authToken, setAuthToken }: any = useAuth();

  useEffect(() => {
    console.log("authToken", authToken);
    if (authToken) {
      navigate("/dashboard");
    } else {
      const currentUrl = window?.location.href;
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl).searchParams;
      const code = searchParams.get("code");
      if (code) {
        setShowLoadingDialog(true);
        const client_secret = `${process.env.REACT_APP_FEDERATION_CLIENT_SECRET}`;
        const authorizeToken = convertToBase64(client_id, client_secret);
        fetch(`${process.env.REACT_APP_FEDERATION_ACCESS_TOKEN_URI}`, {
          method: "POST",
          headers: {
            Authorization: `Basic ${authorizeToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            grant_type: "authorization_code",
            code: code,
            redirect_uri: redirect_uri,
            client_id: client_id,
          }),
        })
          .then(async (response) => {
            const data = await response.json();
            if (data.access_token) {
              localStorage.setItem("token", data?.access_token);
              const token = data?.access_token;
              setAuthToken(token);
              setShowLoadingDialog(false);
              navigate("/dashboard");
            }
          })
          .catch(() => {
            setShowLoadingDialog(false);
          });
      }
    }
  }, [authToken]);

  if (showLoadingDialog) {
    return <ProcessingDialog message="Loading..." />;
  } else {
    return (
      <div className="login-main-container">
        <div className="div-wrapper">
          <div className="logo-with-labels-wrapper-container">
            <div className="logo-with-labels-wrapper">
              <img src={michelinLogo} alt="Michelin Logo" className="logo" />
              <div className="login-text">Login to Mobile platform</div>
              <div>
                <div className="welcome-text">Welcome back!</div>
                <div className="connect-with-id-text">
                  Connect using Michelin ID
                </div>
              </div>
              <button
                className="connect-with-id-button"
                onClick={() => {
                  connectClick();
                }}
              >
                Connect with my Michelin ID
              </button>
            </div>
            <div>
              <div className="login-app-detail-heading">
                This platform offers:
              </div>
              {platformDetailsList.map((item, index) => (
                <div key={index} className="login-page-app-deatil-text">
                  • {item}
                </div>
              ))}
            </div>
          </div>
          <img
            src={loginImageXLarge}
            srcSet={`${loginImageXLarge} 720w, ${loginImageLarge} 444w`}
            sizes="(max-width: 1280px) 444px, (max-width: 1920px) 720px"
            alt="Login Image"
            className="login-image"
          />
          <div className="div-pose">
            <img
              src={loginImagePoseXLarge}
              srcSet={`${loginImagePoseXLarge} 720w, ${loginImagePoseLarge} 44w`}
              sizes="(max-width: 1280px) 444px, (max-width: 1920px) 720px"
              alt="Image Pose"
              className="image-pose"
            />
          </div>
        </div>
      </div>
    );
  }
};

export default LoginScreen;
