import {
  GET_MY_APPS_API_INITIATED,
  GET_MY_APPS_API_SUCCEED,
  GET_MY_APPS_API_FAILED,
} from "../constants/myAppsConstants";

const initialState = {
  myAppsApiCallInitiated: true,
  myAppsApiResponse: {},
  myAppsApiCallFailed: {},
};

const myAppsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MY_APPS_API_INITIATED:
      return {
        myAppsApiCallInitiated: true,
        myAppsApiResponse: {},
        myAppsApiCallFailed: {},
      };
    case GET_MY_APPS_API_SUCCEED:
      return {
        myAppsApiCallInitiated: false,
        myAppsApiResponse: action.payload,
        myAppsApiCallFailed: {},
      };
    case GET_MY_APPS_API_FAILED:
      return {
        myAppsApiCallInitiated: false,
        myAppsApiResponse: {},
        myAppsApiCallFailed: action.payload,
      };
    default:
      return state;
  }
};
export default myAppsReducers;
