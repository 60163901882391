import React, { useEffect, useState } from "react";
import "./ContactUsScreen.scss";
import { initiateApiCall } from "../../api";
import { AxiosError } from "axios";
import { ErrorPopup, ProcessingDialog, SuccessPopup } from "../../components";
import { clearCacheAndCookies } from "../../utils/Utils";
import { useAuth } from "../../routes/useAuth";
import { useNavigate } from "react-router-dom";

interface contactUsInterface {
  [name: string]: string;
}

const ContactUsScreen = () => {
  const { setAuthToken }: any = useAuth();
  const [buttonSelected, setButtonSelected] = useState("Feedback");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });
  const arr = ["Feedback", "Feature Request", "Report An Issue", "Others"];

  const [dataObject, setDataObject] = useState<contactUsInterface>({
    "feedback-type": "FEEDBACK",
    "feedback-title": "",
    "feedback-desc": "",
    "feedback-given-by": "",
  });
  const navigate = useNavigate();

  const handleButtonClick = (value: any) => {
    setButtonSelected(value);
    setDataObject({
      ...dataObject,
      "feedback-type": value.replace(/ /g, "_").toUpperCase(),
    });
  };

  const handleTitleChange = (e: any) => {
    setDataObject({ ...dataObject, "feedback-title": e.target.value.trim() });
  };

  const handleMessageChange = (e: any) => {
    setDataObject({
      ...dataObject,
      "feedback-desc": e.target.value.trim(),
    });
  };

  const handleCloseAPIError = () => {
    setIsErrorPopupVisible(false);
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupVisible(false);
  };

  useEffect(() => {
    setDataObject({
      ...dataObject,
      "feedback-given-by": localStorage.getItem("user_email") || "",
    });
  }, []);

  const handleOnSubmit = async () => {
    try {
      setIsProcessing(true);
      const response: any = await initiateApiCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}` + `/platform/feedback`,
        dataObject,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          apikey: `${process.env.REACT_APP_API_KEY}`,
        }
      );
      if (response.data.code === 201) {
        setIsProcessing(false);
        setIsSuccessPopupVisible(true);
        setDataObject({
          ...dataObject,
          "feedback-title": "",
          "feedback-desc": "",
        });
      }
    } catch (error: any) {
      setIsProcessing(false);
      if (error instanceof AxiosError) {
        if (error?.response?.data.code === 400) {
          setIsProcessing(false);
          setErrorPopupData({
            boldMessage: "Error",
            message: "Bad Request! Please fill the required fields!",
          });
          setIsErrorPopupVisible(true);
        } else if (error?.response?.data.code === 500) {
          setIsProcessing(false);
          setErrorPopupData({
            boldMessage: "Error",
            message: "Internal server error!",
          });
          setIsErrorPopupVisible(true);
        } else if (error?.response?.data.code === 403) {
          setIsProcessing(false);
          setErrorPopupData({
            boldMessage: "Error",
            message: "Forbidden!",
          });
          setIsErrorPopupVisible(true);
        } else if (error?.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else {
          setIsProcessing(false);
          setErrorPopupData({
            boldMessage: "Error",
            message: "Something went wrong! Please contact admin!",
          });
          setIsErrorPopupVisible(true);
        }
      }
    }
  };

  return (
    <div className="main-container">
      {isProcessing && <ProcessingDialog message="Processing..." />}
      <div className="contact-us-container">
        <div className="contact-query-options-wrapper">
          {arr.map((item: any, index: any) => (
            <div
              key={index}
              className={`contact-query-type ${
                buttonSelected === item && "active-button"
              }`}
              onClick={() => handleButtonClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="contact-us-textarea-wrapper">
          <div>
            Title<sup className="red-text asterisk">*</sup>
          </div>
          <input
            className="contact-us-title-input"
            type="text"
            value={dataObject["feedback-title"]}
            onChange={handleTitleChange}
          />
          <div>
            Message<sup className="red-text asterisk">*</sup>
          </div>
          <textarea
            className="contact-us-textarea"
            value={dataObject["feedback-desc"]}
            rows={10}
            onChange={handleMessageChange}
          />
        </div>
      </div>
      <div className="submit-button-wrapper">
        <button className="submit-button" onClick={handleOnSubmit}>
          Submit
        </button>
      </div>
      {isSuccessPopupVisible && (
        <SuccessPopup
          boldMessage="Feedback received!"
          message="Your feedback is submitted successfully!"
          onClose={handleCloseSuccessPopup}
        />
      )}
      {isErrorPopupVisible && (
        <ErrorPopup
          boldMessage={errorPopupData.boldMessage}
          message={errorPopupData.message}
          onClose={handleCloseAPIError}
        />
      )}
    </div>
  );
};

export default ContactUsScreen;
