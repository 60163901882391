import React from "react";
import "./InputField.scss";
import { Tooltip } from "react-tooltip";
import { alertIcon, tooltipIcon } from "../../assets";

type Props = {
  title: string;
  placeholder?: string;
  datatTooltipContent?: string;
  dataTooltipId?: string;
  className?: string;
  styleType?: string;
  disabled?: boolean;
  required?: boolean;
  type?: string;
  onChange: (value: string) => void;
  onBlur?: any;
  value?: string;
  validationError?: string;
  children?: React.ReactNode;
};

const InputField = ({
  title,
  placeholder,
  datatTooltipContent,
  dataTooltipId,
  className,
  styleType = "normal",
  disabled,
  required = false,
  type = "input",
  onChange,
  onBlur,
  value,
  validationError,
  children,
}: Props) => {
  return (
    <>
      <div className="title-field">
        <div>
          <span className={`${disabled ? "title-field-disabled-field" : ""}`}>
            {title}
          </span>
          {required && <sup className="red-text asterisk">*</sup>}
        </div>
        {required && dataTooltipId ? (
          <div className="div-tooltip-icon">
            <img
              src={tooltipIcon}
              data-tooltip-content={datatTooltipContent}
              data-tooltip-id={dataTooltipId}
            />
            <Tooltip id={dataTooltipId} />
          </div>
        ) : (
          <></>
        )}
        {!required && type !== "noText" && dataTooltipId ? (
          <div className="div-tooltip-icon-not-mandatory-fields">
            <img
              src={tooltipIcon}
              data-tooltip-content={datatTooltipContent}
              data-tooltip-id={dataTooltipId}
            />
            <Tooltip id={dataTooltipId} />
          </div>
        ) : (
          <></>
        )}
      </div>
      {type === "textArea" ? (
        <textarea
          placeholder={placeholder}
          className={`textarea-size ${className}`}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          maxLength={500}
        />
      ) : type === "noText" ? (
        <> {children}</>
      ) : (
        <input
          type="text"
          className={`${className} ${
            styleType === "full" ? "full-width" : "input-field"
          }`}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
        />
      )}
      {validationError && (
        <div className="validation-msg-container">
          <img src={alertIcon} alt="alert logo" className="validation-icon" />
          <div className="validation-error">{validationError}</div>
        </div>
      )}
    </>
  );
};

export default InputField;
