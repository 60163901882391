import React from "react";
import "./CustomCheckboxCard.scss";

type Props = {
  icon: string;
  altText: string;
  selectedIcon: string;
  checked: boolean;
  setSelectedStore: any;
};

const CustomCheckboxCard = ({
  icon,
  altText,
  selectedIcon,
  checked,
  setSelectedStore,
}: Props) => {
  return (
    <div className="dashboard-checkbox">
      <input
        type="radio"
        onChange={() => setSelectedStore(altText)}
        checked={checked}
        id={altText}
        name="store"
      />
      <label htmlFor={altText} className="dashboard-checkbox-label">
        <img
          src={checked ? selectedIcon : icon}
          alt={altText}
          className="custom-check-box-image"
        />
      </label>
    </div>
  );
};

export default CustomCheckboxCard;
