import React from "react";
import "./ContactDetails.scss";
import { appDetails } from "../AppDetailsScreen";

interface appDetailsProps {
  contactDetails: appDetails;
}

const ContactDetails = ({ contactDetails }: appDetailsProps) => {
  return (
    <div className="contact-details-wrapper">
      <div className="contact-details-heading-styling">Contact Details</div>
      <div className="contact-details-sub-container">
        <div>
          <div className="contact-details-label">Owner Email</div>
          <div className="contact-details-value">
            {contactDetails["app-details"]["contact-details"]?.[
              "owner-email"
            ]?.trim()
              ? contactDetails["app-details"]["contact-details"]["owner-email"]
              : "NA"}
          </div>
        </div>
        <div>
          <div className="contact-details-label">
            Support Email
          </div>
          <div className="contact-details-value">
            {contactDetails["app-details"]["contact-details"]?.[
              "support-email"
            ]?.trim()
              ? contactDetails["app-details"]["contact-details"][
                  "support-email"
                ]
              : "NA"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
