import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { initiateApiCall } from "../../api";
import {
  ProcessingDialog,
  EmptyMessageComponent,
  InputField,
} from "../../components";
import { AxiosError } from "axios";
import { michelinLogo, emptyBibFace, bibSuccessFace } from "../../assets/index";
import { useNavigate } from "react-router-dom";
import "./RequestStautusPage.scss";

const RequestStatusPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [appDetails, setAppDetails] = useState<any>({});
  const [apiError, setApiError] = useState<string>("");
  const [userFeedback, setUserFeedback] = useState<string>("");

  const getStatus = async () => {
    try {
      setIsProcessing(true);
      const response: any = await initiateApiCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}` + `/app/mgmt/process/verify`,
        null,
        {
          apikey: `${process.env.REACT_APP_API_KEY}`,
          token: state.token,
        },
        {
          "approval-for": state.approvalFor,
        }
      );

      if (response.data.code == 200) {
        setAppDetails(response.data.result);

        if (
          response.data.result["approval-response-state"] === "approved" ||
          response.data.result["approval-response-state"] === "already_approved"
        ) {
          setStatus("apr");
        } else {
          setStatus("rej");
        }
        setIsProcessing(false);
      } else if (response.data.code == 500) {
        setIsProcessing(false);
        setApiError(response.data.error);
      } else {
        setIsProcessing(false);
        setApiError("Opps something went wrong !");
      }
    } catch (error: any) {
      console.log("Error", error);
      setIsProcessing(false);
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        setApiError("Opps something went wrong !");
      }
    }
  };
  useEffect(() => {
    getStatus();
  }, []);

  const submitRejectionReason = async () => {
    try {
      setIsProcessing(true);
      const response: any = await initiateApiCall(
        "patch",
        `${process.env.REACT_APP_BASE_URL}` +
          `/app/mgmt/process/rejection-reason`,
        {
          reason: userFeedback,
        },
        {
          token: state.token,
        }
      );

      if (response.data.code == 200) {
        setIsProcessing(false);
        navigate("/");
      } else if (response.data.code == 500) {
        setIsProcessing(false);
        setApiError(response.data.error);
      } else {
        setIsProcessing(false);
        setApiError("Opps something went wrong !");
      }
    } catch (error: any) {
      console.log("Error", error);
      setIsProcessing(false);
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        setApiError("Opps something went wrong !");
      }
    }
  };

  const getStatusIcon = () => {
    if (status === "apr") {
      return bibSuccessFace;
    } else {
      return emptyBibFace;
    }
  };

  return (
    <div className="main-container">
      {isProcessing ? (
        <ProcessingDialog message="Loading..." />
      ) : apiError ? (
        <EmptyMessageComponent message={apiError} />
      ) : (
        <div className="request-status-container">
          <div className="display-column">
            <img
              src={michelinLogo}
              alt="Michelin Logo"
              className="michelin-logo"
            />
            <div className="app-name">{appDetails["app-name"]}</div>
            <div className="app-description-status-page">
              {appDetails["app-description"]}
            </div>
          </div>
          <div className="display-column">
            <img
              src={getStatusIcon()}
              alt="app-status"
              className="request-app-logo"
            />
            {status === "apr" ? (
              <>
                <div className="approve-text">CONGRATULATION!</div>
                <div className="status-msg">App has been approved!</div>
              </>
            ) : (
              <>
                <div className="reject-text">OOOPSSS!</div>
                <div className="status-msg">App has been Rejected</div>
              </>
            )}
          </div>
          {status === "rej" && (
            <div className="rejection-description">
              <InputField
                title="Please specify the reason for rejection"
                placeholder="Write your comments here…"
                value={userFeedback}
                type="textArea"
                onChange={(value: string) => {
                  setUserFeedback(value);
                }}
              />
              <span className="text-limit">0/80</span>
              <button
                className="request-feedback-submit-button"
                onClick={() => {
                  submitRejectionReason();
                }}
              >
                Submit
              </button>
            </div>
          )}

          <div className="status-button-container">
            {/* <button
              className="close-button"
              onClick={() => {
                navigate("/");
              }}
            >
              Close
            </button> */}
            <button
              className="login-button"
              onClick={() => {
                navigate("/");
              }}
            >
              Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestStatusPage;
