import React from "react";
import "./RegistrarDetails.scss";
import { appDetails } from "../AppDetailsScreen";

interface appDetailsProps {
  registrarDetails: appDetails;
}

const RegistrarDetails = ({ registrarDetails }: appDetailsProps) => {
  return (
    <div className="registrar-details-wrapper">
      <div className="registrar-heading-styling">Registrar Details</div>
      <div className="registrar-details-sub-container">
        <div>
          <div className="registrar-details-label">Registered by</div>
          <div className="registrar-details-value">
            {registrarDetails["app-details"]?.["registrar-details"]?.[
              "registered-by"
            ]?.trim()
              ? registrarDetails["app-details"]?.["registrar-details"]?.[
                  "registered-by"
                ]
              : "NA"}
          </div>
        </div>
        <div>
          <div className="registrar-details-label">
            Registered Email
          </div>
          <div className="registrar-details-value">
            {registrarDetails["app-details"]?.["registrar-details"]?.[
              "registrar-email"
            ]?.trim()
              ? registrarDetails["app-details"]?.["registrar-details"]?.[
                  "registrar-email"
                ]
              : "NA"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrarDetails;
