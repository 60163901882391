import {
  GET_APP_DETAILS_API_INITIATED,
  GET_APP_DETAILS_API_SUCCEED,
  GET_APP_DETAILS_API_FAILED,
} from "../constants/appDetailsConstants";

const initialState = {
  apiCallInitiated: false,
  apiResponse: {},
  apiCallFailed: {},
};

const appDetailsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_APP_DETAILS_API_INITIATED:
      return {
        apiCallInitiated: true,
        apiResponse: {},
        apiCallFailed: {},
      };
    case GET_APP_DETAILS_API_SUCCEED:
      return {
        apiCallInitiated: false,
        apiResponse: action.payload,
        apiCallFailed: {},
      };
    case GET_APP_DETAILS_API_FAILED:
      return {
        apiCallInitiated: false,
        apiResponse: {},
        apiCallFailed: action.payload,
      };
    default:
      return state;
  }
};
export default appDetailsReducers;
