import React, { useEffect, useRef, useState } from "react";
import "./DashboardScreen.scss";
import {
  appstoreIcon,
  appstoreSelectedIcon,
  playstoreIcon,
  playstoreSelectedIcon,
} from "../../assets";
import { useDispatch } from "react-redux";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../../interfaces/common/ErrorResponse";
import { initiateApiCall } from "../../api";
import { useNavigate } from "react-router-dom";
import { clearCacheAndCookies } from "../../utils/Utils";
import { useAuth } from "../../routes/useAuth";
import {
  ErrorPopup,
  ProcessingDialog,
  AppCountComponent,
  CustomCheckboxCard,
  PaginationComponent,
  AllAppsComponent,
  TabsComponent,
} from "../../components";

interface ApiResponse {
  code: number;
  message: string;
  result: ResultData;
  error: string;
}

interface AppStatisticsApiResponse {
  code: number;
  message: string;
  result: AppStatisticsResultData;
  error: string;
}

interface AppStatisticsResultData {
  "app-statistics": AppStatisticsData;
}

export interface AppStatisticsData {
  overall: AppCountData;
  registered: AppCountData;
  unregistered: AppCountData;
}

interface AppCountData {
  total: number;
  android: number;
  ios: number;
}

interface ResultData {
  "all-apps": AppData[];
  page: number;
  limit: number;
  "total-pages": number;
  "total-results": number;
}

export interface AppData {
  "package-name": string;
  "platform-name": string;
  "store-app-name": string;
  "app-logo": string;
  "app-account-holder": string | null;
  "platform-registered": boolean;
  "avg-rating": number;
  "store-app-logo": string;
  "app-status": {
    "version-string": string;
    status: string;
    "app-version-state": string;
    reason: string | null;
  };
}

const DashboardScreen = () => {
  const { setAuthToken }: any = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedTab, setSelectedTab] = useState("all");
  const [allApps, setAllApps] = useState<AppData[]>([]);
  const [searchValue, setSearchValue] = useState<AppData[]>([]);
  const [lastFiveSearch, setLastFiveSearch] = useState<string[]>([]);
  const [isSearched, setIsSearched] = useState(false);
  const [filteredApps, setFilteredApps] = useState<AppData[]>([]);
  const [filterClicked, setFilteredClicked] = useState(false);
  const navigate = useNavigate();
  const [appStatistics, setAppStatistics] = useState<AppStatisticsData>({
    overall: {
      total: 0,
      android: 0,
      ios: 0,
    },
    registered: {
      total: 0,
      android: 0,
      ios: 0,
    },
    unregistered: {
      total: 0,
      android: 0,
      ios: 0,
    },
  });
  const [showSearchList, setShowSearchList] = useState<string[]>([]);

  const tabs = [
    { label: "All", value: "all" },
    { label: "Registered", value: "registered" },
    { label: "Unregistered", value: "unregistered" },
  ];

  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState("");
  const [showValues, setShowValues] = useState(false);
  const descRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const handleSelectedSearch = (selectedValue: any) => {
    setSelectedSearch(selectedValue);
    setSearchValue(
      allApps.filter((item) =>
        item["store-app-name"]
          ?.toLowerCase()
          .includes(selectedValue.toLowerCase())
      )
    );
    setIsSearched(true);
  };

  const setTab = (tab: string) => {
    setSelectedTab(tab);
    if (tab === "all") {
      setSelectedStore("");
    }
  };

  const setStore = (store: string) => {
    setSelectedStore(store);
  };

  useEffect(() => {
    setFilteredClicked(true);
    filterApps();
  }, [selectedTab, searchValue]);

  useEffect(() => {
    setFilteredClicked(true);
    filterApps();
  }, [selectedStore, searchValue]);

  useEffect(() => {
    return;
  }, [isSearched]);

  useEffect(() => {
    const values = Array.from(
      new Set(
        lastFiveSearch
          .slice(Math.max(lastFiveSearch.length - 5, 0))
          .filter((item: string) => item !== "")
      )
    );
    if (values.length > 0) {
      localStorage.setItem("searches", JSON.stringify(values));
    }
  }, [lastFiveSearch]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        descRef.current &&
        !descRef.current.contains(event.target) &&
        showValues
      ) {
        setShowValues(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [descRef]);

  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });

  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);

  const handleError = (error: ErrorResponse) => {
    console.log("handleError: ", error);
    setErrorPopupData({
      boldMessage: error.error,
      message: error.message,
    });
    setIsErrorPopupVisible(true);
  };

  const filterApps = () => {
    let apps = searchValue.length > 0 ? searchValue : allApps;
    setFilteredClicked(true);

    if (selectedTab !== "all") {
      apps = apps.filter((app) =>
        selectedTab === "registered"
          ? app["platform-registered"]
          : !app["platform-registered"]
      );
    }

    if (selectedStore !== "") {
      apps = apps.filter((app) =>
        selectedStore === "android"
          ? app["platform-name"] === "android"
          : app["platform-name"] === "ios"
      );
    }
    setFilteredApps(apps);
  };

  const handleCloseError = () => {
    setErrorPopupData({
      boldMessage: "",
      message: "",
    });
    setIsErrorPopupVisible(false);
  };

  const handleNextPageClicked = (pageNumber: number) => {
    console.log("Selected Page Number: ", pageNumber);
    fetchAllApps(pageNumber - 1, 50);
  };

  const handleMouseEnter = () => {
    setShowValues((prevState) => !prevState);
    setShowSearchList(JSON.parse(localStorage.getItem("searches") || "[]"));
  };

  const handleMouseLeave = () => {
    setShowValues((prevState) => !prevState);
  };

  const Values = () => (
    <div className="search-dropdown">
      {showSearchList.length > 0 && (
        <div className="searched-values">
          {showSearchList.map((item: any, index: any) => (
            <div
              className="searched-value"
              key={index}
              onClick={() => handleSelectedSearch(item)}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const handleSearch = (e: any) => {
    setSelectedSearch(e.target.value);

    if (e.target.value.length > 2) {
      setTimeout(() => {
        setLastFiveSearch([...lastFiveSearch, e.target.value]);
      }, 5000);
      setTimeout(() => {
        searchRef.current?.blur();
      }, 10000);

      setIsSearched(true);
      setSearchValue(
        allApps.filter((item) =>
          item["store-app-name"]
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    } else if (e.target.value == "") {
      setIsSearched(false);
      setSearchValue(allApps);
    }
  };

  const fetchAllApps = async (pageNumber: number, pageLimit: number) => {
    console.log("App token -> ", localStorage.getItem("token"));
    setShowProgressDialog(true);
    setFilteredClicked(false);
    setSelectedStore("");
    try {
      const userInfo = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_FEDERATION_USER_INFO_URI}`,
        null,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      );
      if (userInfo.data.email !== null) {
        localStorage.setItem("user_email", userInfo.data.email);
      }

      const response: AxiosResponse<ApiResponse> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/app/find/all-apps`,
        null,
        {
          apikey: `${process.env.REACT_APP_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        {
          page: pageNumber,
          limit: pageLimit,
          sortBy: "appName",
        }
      );

      await fetchAppStatistics();

      if (response.data.code === 200) {
        console.log("Success Response: ", response.data.result);
        const allApps = response.data.result["all-apps"].sort((a, b) => {
          if (a["platform-registered"] === b["platform-registered"]) {
            return a["store-app-name"]?.localeCompare(b["store-app-name"]);
          }
          return a["platform-registered"] ? -1 : 1;
        });
        setAllApps(allApps);
        setTotalPages(response.data.result["total-pages"]);
        setShowProgressDialog(false);
      } else if (response.data.code === 401) {
        setShowProgressDialog(false);
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      } else {
        console.log("Failed Response: ", response.data);
        setIsErrorPopupVisible(true);
        setShowProgressDialog(false);
      }
    } catch (error: any) {
      console.log("Error: ", error);
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        console.log(
          "Inside error response status: ",
          axiosError.response?.status
        );
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleError(axiosError.response.data as ErrorResponse);
          console.log("Axios error: ", axiosError.response.data);
        } else {
          console.log(error);
          handleError({
            code: 500,
            message: "Internal Server Error",
            result: null,
            error: "Internal Server Error",
          });
        }
      }
      setShowProgressDialog(false);
    }
  };

  const fetchAppStatistics = async () => {
    setShowProgressDialog(true);
    try {
      const response: AxiosResponse<AppStatisticsApiResponse> =
        await initiateApiCall(
          "get",
          `${process.env.REACT_APP_BASE_URL}/app/analytics/statistics`,
          null,
          {
            apikey: `${process.env.REACT_APP_API_KEY}`,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        );

      if (response.data.code === 200) {
        console.log("Success Response: ", response.data.result);
        setAppStatistics(response.data.result["app-statistics"]);
        setShowProgressDialog(false);
      } else {
        console.log("Failed Response: ", response.data);
        setIsErrorPopupVisible(true);
        setShowProgressDialog(false);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleError(axiosError.response.data as ErrorResponse);
          console.log("Axios error: ", axiosError.response.data);
        } else {
          console.log(error);
          handleError({
            code: 500,
            message: "Internal Server Error",
            result: null,
            error: "Internal Server Error",
          });
        }
      }
      setShowProgressDialog(false);
    }
  };

  useEffect(() => {
    fetchAllApps(0, 1000);
  }, []);

  return (
    <div className="main-container">
      {isErrorPopupVisible && (
        <ErrorPopup
          boldMessage={errorPopupData.boldMessage}
          message={errorPopupData.message}
          onClose={handleCloseError}
        />
      )}
      {showProgressDialog ? (
        <ProcessingDialog message="Loading..." />
      ) : (
        <div className="dashboard-sub-container">
          <div className="title-text-dark bold">Dashboard</div>
          <AppCountComponent appStatisticsData={appStatistics} />
          <div className="title-text-dark bold">Available Apps</div>
          <div className="dashboard-tabs-row">
            <div className="dashboard-flex-row">
              <TabsComponent
                selectedTab={selectedTab}
                setTab={setTab}
                tabs={tabs}
              />
              <CustomCheckboxCard
                icon={playstoreIcon}
                selectedIcon={playstoreSelectedIcon}
                altText="android"
                checked={selectedStore === "android"}
                setSelectedStore={setStore}
              />
              <CustomCheckboxCard
                icon={appstoreIcon}
                selectedIcon={appstoreSelectedIcon}
                altText="ios"
                checked={selectedStore === "ios"}
                setSelectedStore={setStore}
              />
            </div>

            <div
              className="search-div-wrapper"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              ref={descRef}
            >
              <input
                type="text"
                value={selectedSearch}
                className="search-box"
                placeholder="Search apps"
                onChange={handleSearch}
                ref={searchRef}
              />
              {showSearchList.length > 0 && showValues ? <Values /> : null}
            </div>
          </div>

          {searchValue.length === 0 && isSearched ? (
            <AllAppsComponent data={[]} />
          ) : (
            <AllAppsComponent data={filterClicked ? filteredApps : allApps} />
          )}
          {/* {totalPages > 0 && <PaginationComponent totalPageNumber={totalPages} nextPageClicked={handleNextPageClicked} />} */}
        </div>
      )}
    </div>
  );
};

export default DashboardScreen;
