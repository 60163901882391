import React from "react";
import "./CapsuleComponent.scss";
import { crossIcon } from "../../assets";
import { LineOfBusiness } from "../../screens/registerAppScreen/RegisterAppScreen";

type Props = {
  businessLines: LineOfBusiness[];
  onSelect: (line: LineOfBusiness, operation?: string) => void;
  selectedLBs: LineOfBusiness[];
};

const CapsuleComponent = ({ businessLines, onSelect, selectedLBs }: Props) => {
  return (
    <div
      className={
        "main-capsule-container" + (selectedLBs.length > 0 ? " chosen" : "")
      }
    >
      <div className="text-style">Select Business Line</div>
      <div className={"capsule-container"}>
        {selectedLBs.length > 0 && (
          <div className="seleted-container">
            {selectedLBs.map((line, index) => (
              <button key={index} className="capsule-button active">
                {line["line-of-business"]}
                <img
                  src={crossIcon}
                  alt="close"
                  className="close-icon"
                  onClick={() => onSelect(line, "remove")}
                />
              </button>
            ))}
            <div
              className="clear-button"
              onClick={() => onSelect({ id: 0, "line-of-business": "", "business-direction": "" }, "clearAll")}
            >
              Clear
            </div>
          </div>
        )}
        {businessLines.map((line, index) => (
          <button
            key={index}
            className="capsule-button"
            onClick={() => onSelect(line)}
          >
            {line["line-of-business"]}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CapsuleComponent;
