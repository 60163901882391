import React from "react";
import "./TabsComponent.scss";

type TabsComponentProps = {
  selectedTab: string;
  setTab: (tab: string) => void;
  tabs: Array<{ label: string; value: string }>;
  tabBackground?: string;
  setReviewTypeChangedFlag?: any;
  setReviewPageNumber?: any;
  setApiCallFlag?: any;
};

const tabBackgroundColors: any = {
  default: "tab-background-white",
  primary: "tab-background-grey",
};

const TabsComponent = ({
  selectedTab,
  setTab,
  tabs,
  tabBackground = "default",
  setReviewTypeChangedFlag,
  setReviewPageNumber,
  setApiCallFlag,
}: TabsComponentProps) => {
  return (
    <div className="tab-component-tab-container">
      <div className={`tab ${tabBackgroundColors[tabBackground]}`}>
        {tabs.map(({ label, value }, index, tabs) => (
          <button
            key={index}
            className={`${selectedTab === value && "active"} ${
              index + 1 === tabs.length && "last"
            }`}
            onClick={() => {
              setTab(value);
              setReviewTypeChangedFlag && setReviewTypeChangedFlag(true);
              setReviewPageNumber && setReviewPageNumber(0);
              setApiCallFlag && setApiCallFlag(true);
            }}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabsComponent;
