import React from "react";
import "./Countries.scss";
import { getSliceValueBasedOnScreenSize } from "../../../utils/Utils";
import { appDetails } from "../AppDetailsScreen";

interface appDetailsProps {
  countriesData: appDetails;
}

const Countries = ({ countriesData }: appDetailsProps) => {
  const slicedItems: string[] =
    countriesData["app-details"]["territories"].length > 0
      ? countriesData["app-details"]["territories"].slice(
          0,
          getSliceValueBasedOnScreenSize()
        )
      : [];
  const slicedItemsDropDown: string[] =
    countriesData["app-details"]["territories"].length > 0
      ? countriesData["app-details"]["territories"].slice(
          getSliceValueBasedOnScreenSize()
        )
      : [];

  const [showValues, setShowValues] = React.useState(false);
  const onClick = () => setShowValues((prevState) => !prevState);

  const Values = () => (
    <div className="div-dropdown-countries">
      {slicedItemsDropDown.length > 0 &&
        slicedItemsDropDown.map((item, index) => (
          <div className="show-more-results-countries" key={index}>
            {item}
          </div>
        ))}
    </div>
  );

  return (
    <>
      {countriesData["app-details"]["territories"].length > 0 ? (
        <>
          <div className="countries-heading-styling">Countries</div>
          <div className="countries-lines-wrapper">
            {slicedItems.length > 0 &&
              slicedItems.map((item, index) => (
                <div key={index}>
                  <div className="countries-lines-values">{item}</div>
                </div>
              ))}
            <div>
              <div className="show-more-number-countries" onClick={onClick}>
                {countriesData["app-details"]["territories"].length > 10 &&
                  "more.."}
              </div>
              {showValues ? <Values /> : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="countries-heading-styling">Countries</div>
          <div className="countries-lines-wrapper">NA</div>
        </>
      )}
    </>
  );
};

export default Countries;
