import {
    GET_MY_APPS_API_INITIATED,
    GET_MY_APPS_API_SUCCEED,
    GET_MY_APPS_API_FAILED,
} from "../constants/myAppsConstants";

export const getMyAppsApiInitiated = () => ({
    type: GET_MY_APPS_API_INITIATED,
});

export const getMyAppsApiSucceed = (payload: any) => ({
    type: GET_MY_APPS_API_SUCCEED,
    payload: payload,
});

export const getMyAppsApiFailed = (payload: any) => ({
    type: GET_MY_APPS_API_FAILED,
    payload: payload,
});
