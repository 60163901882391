import React from "react";
import "./ProcessingDialog.scss";
import { bibLoader } from "../../../assets";

interface ProcessingDialogProps {
  message: string;
}

const ProcessingDialog: React.FC<ProcessingDialogProps> = ({ message }) => {
  return (
    <div className="processing-dialog-overlay">
      <div className="processing-dialog">
        <img src={bibLoader} alt="Loading..." className="img" />
      </div>
    </div>
  );
};

export default ProcessingDialog;
