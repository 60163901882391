import React from "react";
import "./BusinessLines.scss";
import { getSliceValueBasedOnScreenSize } from "../../../utils/Utils";
import { appDetails } from "../AppDetailsScreen";

interface appDetailsProps {
  businessLines: appDetails;
}

type businessLinesObject = {
  id: number;
  "line-of-business": string;
  "business-direction": string;
};

const BusinessLines = ({ businessLines }: appDetailsProps) => {
  const sliceSize: any = getSliceValueBasedOnScreenSize();
  const slicedItems: businessLinesObject[] =
    businessLines["app-details"]["business-lines"].length > 0
      ? businessLines["app-details"]["business-lines"].slice(0, sliceSize)
      : [];
  const slicedItemsDropDown: businessLinesObject[] =
    businessLines["app-details"]["business-lines"].length > 0
      ? businessLines["app-details"]["business-lines"].slice(sliceSize)
      : [];

  const [showValues, setShowValues] = React.useState(false);
  const onClick = () => setShowValues((prevState) => !prevState);

  const Values = () => (
    <div className="div-dropdown">
      {slicedItemsDropDown.map((item, index) => (
        <div className="show-more-results" key={index}>
          {item["line-of-business"]}
        </div>
      ))}
    </div>
  );

  return (
    <div>
      {businessLines["app-details"]["business-lines"].length > 0 ? (
        <>
          <div className="business-lines-heading-styling">
            Business Line / Corporate Direction / Operational Direction
          </div>
          <div className="business-lines-wrapper">
            {slicedItems.map((item, index) => (
              <div key={index}>
                <div className="business-lines-values">
                  {item["line-of-business"]}
                </div>
              </div>
            ))}
            <div>
              <div className="show-more-number" onClick={onClick}>
                {businessLines["app-details"]["business-lines"].length >
                  sliceSize && "more.."}
              </div>
              {showValues ? <Values /> : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="business-lines-heading-styling">
            Business Line / Corporate Direction / Operational Direction
          </div>
          <div className="business-lines-wrapper">NA</div>
        </>
      )}
    </div>
  );
};

export default BusinessLines;
