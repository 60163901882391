export const textStyle = {
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  font: "bold 1.14rem Noto Sans",
  lineHeight: 1.5,
};

export const selectedText = {
  fontWeight: "bold",
  color: "#000000",
};

export const plainText = {
  fontWeight: "normal",
  color: "#27509b",
};
