import React, { useEffect, useState } from "react";
import { initiateApiCall } from "../../api";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  getMyAppsApiFailed,
  getMyAppsApiInitiated,
  getMyAppsApiSucceed,
} from "../../stateManagement/actions/myAppsActions";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../../interfaces/common/ErrorResponse";

import "./MyAppScreen.scss";
import MyAppsCardContent from "./cardContent/MyAppsCardContent";
import { Card, ErrorPopup, ProcessingDialog } from "../../components";
import { clearCacheAndCookies } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import EmptyMessageComponent from "../../components/emptyMessageComponent/EmptyMessageComponent";
import { useAuth } from "../../routes/useAuth";
interface ApiResponse {
  code: number;
  message: string;
  result: ResultData;
  error: string;
}

interface ResultData {
  "my-apps": AppData[];
  page: number;
  limit: number;
  "total-pages": number;
  "total-results": number;
}

interface AppData {
  "package-name": string;
  "platform-name": string;
  "app-name": string;
  "app-description": string;
  "app-logo": string;
  "app-account-holder": string | null;
  "platform-registered": boolean;
  "app-last-published-date": string | null;
  "avg-rating": number;
  "no-of-reviews": number;
  "no-of-downloads": number;
  "store-app-logo": string;
  "store-app-description": string;
  "app-status": {
    "version-string": string;
    status: string;
    "app-version-state": string;
    reason: string | null;
  };
  "app-approval-status": string;
}

const MyAppsScreen = () => {
  const { setAuthToken }: any = useAuth();
  const { myAppsApiCallInitiated, myAppsApiResponse } = useSelector(
    (state: any) => state.myAppsReducers,
    shallowEqual
  );

  // const renderCards = () => {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);

  const handleError = (error: ErrorResponse) => {
    console.log("handleError: ", error);
    setErrorPopupData({
      boldMessage: error.error,
      message: error.message,
    });
    setIsErrorPopupVisible(true);
  };

  const handleCloseError = () => {
    setErrorPopupData({
      boldMessage: "",
      message: "",
    });
    setIsErrorPopupVisible(false);
  };

  //TODO: This API is not final. Header parameter USER is temporary for now this will be removed once we have the authentication in place.
  const fetchMyApps = async () => {
    dispatch(getMyAppsApiInitiated());
    try {
      const response: AxiosResponse<ApiResponse> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/app/find/my-apps`,
        null,
        {
          apikey: `${process.env.REACT_APP_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          User: `${localStorage.getItem("user_email")}`,
        }
      );

      if (response.data.code === 200) {
        dispatch(getMyAppsApiSucceed(response.data));
      } else {
        console.log("Failed Response: ", response.data);
        dispatch(getMyAppsApiFailed(response.data));
        setErrorPopupData({
          boldMessage: response.data.error,
          message: response.data.message,
        });
        setIsErrorPopupVisible(true);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleError(axiosError.response.data as ErrorResponse);
          console.log("Axios error: ", axiosError.response.data);
        } else {
          console.log(error);
          handleError({
            code: 500,
            message: "Internal Server Error",
            result: null,
            error: "Internal Server Error",
          });
        }
        dispatch(getMyAppsApiFailed(error));
      }
    }
  };

  const getRegisteredApps = () => {
    const appList = myAppsApiResponse.result["my-apps"];
    return appList.filter(
      (app: AppData) => app["app-approval-status"] === "full_registered"
    );
  };

  const getSubmittedApps = () => {
    const appList = myAppsApiResponse.result["my-apps"];
    return appList.filter(
      (app: AppData) => app["app-approval-status"] === "soft_registered"
    );
  };

  const getRejectedApps = () => {
    const appList = myAppsApiResponse.result["my-apps"];
    return appList.filter(
      (app: AppData) => app["app-approval-status"] === "rejected"
    );
  };

  useEffect(() => {
    fetchMyApps();
  }, []);

  return (
    <div className="main-container">
      {myAppsApiCallInitiated ? (
        <ProcessingDialog message="Loading..." />
      ) : !myAppsApiCallInitiated &&
        !myAppsApiResponse.result["my-apps"]?.length ? (
        <EmptyMessageComponent
          message="Sorry, you have no apps registered. 
Please register your app"
        />
      ) : (
        <div className="myApps-container">
          {getRegisteredApps().length > 0 && (
            <div className="myApps-heading">Registered Apps</div>
          )}
          <div className="myApps-card-container">
            {getRegisteredApps().map((data: AppData, index: any) => {
              return (
                <Card key={index}>
                  <MyAppsCardContent
                    appName={data["app-name"]}
                    appDescription={
                      data["app-description"] || data["store-app-description"]
                    }
                    appLogo={data["app-logo"]}
                    appAccountHolderName={data["app-account-holder"]}
                    appLastUpdateDate={data["app-last-published-date"]}
                    averageRating={data["avg-rating"]}
                    noOfReviews={data["no-of-reviews"]}
                    noOfDownloads={data["no-of-downloads"]}
                    platformRegistered={data["platform-registered"]}
                    packageName={data["package-name"]}
                    platformName={data["platform-name"]}
                    appStoreLogo={data["store-app-logo"]}
                    appStatus={data["app-status"]["status"]}
                    appApprovalStatus={data["app-approval-status"]}
                  />
                </Card>
              );
            })}
          </div>
          {getSubmittedApps().length > 0 && (
            <div className="myApps-heading">Submitted Apps</div>
          )}
          <div className="myApps-card-container">
            {getSubmittedApps().map((data: AppData, index: any) => {
              return (
                <Card key={index}>
                  <MyAppsCardContent
                    appName={data["app-name"]}
                    appDescription={
                      data["app-description"] || data["store-app-description"]
                    }
                    appLogo={data["app-logo"]}
                    appAccountHolderName={data["app-account-holder"]}
                    appLastUpdateDate={data["app-last-published-date"]}
                    averageRating={data["avg-rating"]}
                    noOfReviews={data["no-of-reviews"]}
                    noOfDownloads={data["no-of-downloads"]}
                    platformRegistered={data["platform-registered"]}
                    packageName={data["package-name"]}
                    platformName={data["platform-name"]}
                    appStoreLogo={data["store-app-logo"]}
                    appStatus={data["app-status"]["status"]}
                    appApprovalStatus={data["app-approval-status"]}
                  />
                </Card>
              );
            })}
          </div>
          {getRejectedApps().length > 0 && (
            <div className="myApps-heading">Rejected Apps</div>
          )}
          <div className="myApps-card-container">
            {getRejectedApps().map((data: AppData, index: any) => {
              return (
                <Card key={index}>
                  <MyAppsCardContent
                    appName={data["app-name"]}
                    appDescription={
                      data["app-description"] || data["store-app-description"]
                    }
                    appLogo={data["app-logo"]}
                    appAccountHolderName={data["app-account-holder"]}
                    appLastUpdateDate={data["app-last-published-date"]}
                    averageRating={data["avg-rating"]}
                    noOfReviews={data["no-of-reviews"]}
                    noOfDownloads={data["no-of-downloads"]}
                    platformRegistered={data["platform-registered"]}
                    packageName={data["package-name"]}
                    platformName={data["platform-name"]}
                    appStoreLogo={data["store-app-logo"]}
                    appStatus={data["app-status"]["status"]}
                    appApprovalStatus={data["app-approval-status"]}
                  />
                </Card>
              );
            })}
          </div>
          {isErrorPopupVisible && (
            <ErrorPopup
              boldMessage={errorPopupData.boldMessage}
              message={errorPopupData.message}
              onClose={handleCloseError}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MyAppsScreen;
