import React from "react";
import "./AppAnalyticsHomeScreen.scss";
import {
  analyticsByBusinessLinesXLarge,
  analyticsByBusinessLinesLarge,
  analyticsByStatusXLarge,
  analyticsByStatusLarge,
  analyticsByRegionXLarge,
  analyticsByRegionLarge,
  analyticsByPersonaXLarge,
  analyticsByPersonaLarge,
} from "../../assets/index";
import { useNavigate } from "react-router-dom";

const AppAnalyticsHomeScreen = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/appAnalytics");
  };

  return (
    <div className="main-container">
      <div className="app-analytics-heading-home-screen">APP ANALYTICS</div>
      <div className="analytics-home-screen-container">
        <div className="analytics-categories">
          <picture>
            <source
              srcSet={analyticsByBusinessLinesLarge}
              media="(max-width: 1280px)"
              width="430"
            />
            <img
              className="analytics-images"
              srcSet={analyticsByBusinessLinesXLarge}
              alt="Analytics by Business Lines"
            />
          </picture>
          <div className="analytics-categories-label">
            Analytics By Business Line
          </div>
          <div className="analytics-categories-description">
            Categorization by Business Lines/Business Directions
          </div>
          <button className="start-analytics-button" onClick={handleOnClick}>
            Start
          </button>
        </div>
        <div className="analytics-categories">
          <picture>
            <source
              srcSet={analyticsByStatusLarge}
              media="(max-width: 1280px)"
              width="430"
            />
            <img
              className="analytics-images"
              srcSet={analyticsByStatusXLarge}
              alt="Analytics by Status"
            />
          </picture>
          <div className="analytics-categories-label">Analytics By Status</div>
          <div className="analytics-categories-description">
            Categorization by App Status
          </div>
          <button className="start-analytics-button">Coming soon..</button>
        </div>
        <div className="analytics-categories">
          <picture>
            <source
              srcSet={analyticsByRegionLarge}
              media="(max-width: 1280px)"
              width="430"
            />
            <img
              className="analytics-images"
              srcSet={analyticsByRegionXLarge}
              alt="Analytics by Region"
            />
          </picture>
          <div className="analytics-categories-label">Analytics By Region</div>
          <div className="analytics-categories-description">
            Categorization by Region
          </div>
          <button className="start-analytics-button">Coming soon..</button>
        </div>
        <div className="analytics-categories">
          <picture>
            <source
              srcSet={analyticsByPersonaLarge}
              media="(max-width: 1280px)"
              width="430"
            />
            <img
              className="analytics-images"
              srcSet={analyticsByPersonaXLarge}
              alt="Analytics by Persona"
            />
          </picture>
          <div className="analytics-categories-label">Analytics By Persona</div>
          <div className="analytics-categories-description">
            Categorization by Persona
          </div>
          <button className="start-analytics-button">Coming soon..</button>
        </div>
      </div>
    </div>
  );
};

export default AppAnalyticsHomeScreen;
