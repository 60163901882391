import React from "react";
import ProgressBarWithLabel from "../../../../components/progressBarWithLabel/ProgressBarWithLabel";
import "./AppReviewsComponent.scss";
import StarRatingsComponent from "../../../../components/starRatingsComponent/StarRatingsComponent";
import TabsComponent from "../../../../components/tabsComponent/TabsComponent";
import ReviewCardComponent from "../../../../components/reviewCardComponent/ReviewCardComponent";
import { formatNumberToK } from "../../../../utils/Utils";

type AppReviewsProps = {
  setEnablePopup: any;
  reviewsData: any;
  setReviewType: any;
  selectedTab: string;
  setReviewTypeChangedFlag: any;
  setReviewPageNumber: any;
  setApiCallFlag: any;
  checkRatingPercentage: any;
};

const AppReviewsComponent = ({
  setEnablePopup,
  reviewsData,
  setReviewType,
  selectedTab,
  setReviewTypeChangedFlag,
  setReviewPageNumber,
  setApiCallFlag,
  checkRatingPercentage,
}: AppReviewsProps) => {
  const tabs = [
    { label: "All", value: "All" },
    { label: "Positive", value: "Positive" },
    { label: "Neutral", value: "Neutral" },
    { label: "Negative", value: "Negative" },
  ];

  const reviewsToDisplay = reviewsData["app-review-list"].slice(0, 4);

  const ratings = [
    { label: "5", value: reviewsData["five-star-rating-percentage"] },
    { label: "4", value: reviewsData["four-star-rating-percentage"] },
    { label: "3", value: reviewsData["three-star-rating-percentage"] },
    { label: "2", value: reviewsData["two-star-rating-percentage"] },
    { label: "1", value: reviewsData["one-star-rating-percentage"] },
  ];

  return (
    <div className="app-reviews-ratings">
      <div className="app-reviews-heading-styling">App Reviews</div>
      <div className="app-review-box">
        {checkRatingPercentage() && (
          <div className="main-review-container">
            <div className="bold-text">
              {reviewsData["average-rating"]}
              <StarRatingsComponent rating={reviewsData["average-rating"]} />
              <p>({formatNumberToK(reviewsData["total-reviews"])} reviews)</p>
            </div>
            <ProgressBarWithLabel ratings={ratings} />
          </div>
        )}
        <div>
          <TabsComponent
            tabs={tabs}
            selectedTab={selectedTab}
            setTab={setReviewType}
            setReviewTypeChangedFlag={setReviewTypeChangedFlag}
            setReviewPageNumber={setReviewPageNumber}
            setApiCallFlag={setApiCallFlag}
          />
          <div className="reviews-box">
            <ReviewCardComponent reviews={reviewsToDisplay} />
          </div>
          {reviewsData["app-review-list"].length > 4 && (
            <u
              className="view-all-reviews"
              onClick={() => setEnablePopup(true)}
            >
              See all reviews
            </u>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppReviewsComponent;
