import { combineReducers } from "redux";
import registerAppReducers from "./registerAppReducers";
import myAppsReducers from "./myAppsReducers";
import dashboardReducers from "./dashboardReducers";
import appDetailsReducers from "./appDetailsReducers";

export default combineReducers({
  registerAppReducers,
  myAppsReducers,
  dashboardReducers,
  appDetailsReducers,
});
