import React, { useState, useCallback } from "react";
import { THEMES } from "./theme/themeConstants";
import { ThemeContext } from "./theme/themeContext";
import AppContainer from "./routes/index";
import "./App.scss";
import { Provider } from "react-redux";
import store from "./stateManagement/store/store";
import { BrowserRouter } from "react-router-dom";

function App() {
  const [theme, setTheme] = useState(THEMES.DEFAULT);
  const updateTheme = useCallback(
    (newTheme: string) => setTheme(newTheme),
    [setTheme]
  );
  const value: any = {
    theme,
    updateTheme,
  };

  return (
    <Provider store={store}>
      <ThemeContext.Provider value={value}>
        <BrowserRouter>
          <div className={`theme-${theme}`}>
            <div className="app">
              <>
                <AppContainer />
              </>
            </div>
          </div>
        </BrowserRouter>
      </ThemeContext.Provider>
    </Provider>
  );
}

export default App;
