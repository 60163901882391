import React from "react";
import "./EmptyMessageComponent.scss";
import { emptyBibFace } from "../../assets";

type EmptyMessageComponentProps = {
  message: string;
  className?: string;
};
const EmptyMessageComponent = ({
  message,
  className,
}: EmptyMessageComponentProps) => {
  return (
    <div className={`${className ? className : "empty-message-container"}`}>
      <img src={emptyBibFace} alt="Error" className="empty-message-image" />
      <div className="empty-message-text">{message}</div>
    </div>
  );
};

export default EmptyMessageComponent;
